import { buildErrorMetaObject } from './buildErrorMetaObject';
export const silenceErrorAlert = ({
  isVisibleToUser = false,
  ignoreStatusCodes = [0],
  error
} = {}) => {
  const isIgnored = error && ignoreStatusCodes.some(code => code === error.status);
  if (isIgnored) return buildErrorMetaObject({
    ignore: true
  });
  return buildErrorMetaObject({
    silent: true,
    isVisibleToUser
  });
};