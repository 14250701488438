import { buildErrorMetaObject } from './buildErrorMetaObject';

/**
 * @description Display a floating alert for a request failure.
 *
 * @example
 * const fetchContactError = createAction(
 *   ActionTypes.GET_CONTACT_FAILED,
 *   error => ({ error }),
 *   error => buildRequestErrorMetaObject({ ignoreStatusCodes: [404], error })
 * );
 */
export const buildRequestErrorMetaObject = ({
  titleText,
  message,
  ignoreStatusCodes = [0],
  error
}) => {
  const isIgnored = ignoreStatusCodes.some(code => code === error.status);
  if (isIgnored) return buildErrorMetaObject({
    ignore: true
  });
  return buildErrorMetaObject({
    titleText,
    message
  });
};